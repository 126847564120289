<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Create - {{name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text to="/business-partners/properties">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor :initial="dataItem" @data="save"></editor>
        <!-- end -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/children-editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    name: null,
    GroupCode: null,
    dataItem: {},
  }),
  watch: {
    "$route.params.id": {
      handler: "setId",
      immediate: true,
    },
  },
  methods: {
    setId(val) {
      this.GroupCode = val;
      const self = this;
      this.$store
        .dispatch("get", `/bp_properties/${val}`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.name = res.ResponseData.GroupName;
          }
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      data.GroupCode = this.GroupCode;
      const url = "/bp_properties_desc";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.$router.push(
              `/bp/properties/${self.GroupCode}`
            );
          } else {
            this.$refs.snackbar.show(res.msg, "red");
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>